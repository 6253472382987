import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { filter, map, Observable } from 'rxjs';
import { PermissionsService, UserLevel } from './services/permissions.service';
import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-061654}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SIEMENS_MOBILITY_LIMITED}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Cab_Radio_Management_Terminal}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Cab_Radio_Management_Terminal}_need_to_be_licensed___{Cab_Radio_Management_Terminal}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_July_2025}____[v3]_[01]_MTc1MjM2MTIwMDAwMA==0c3670a7abc472d12b97aabf3e7c494a")

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'crmt-gui';

  public name$!: Observable<string>;

  public disableStandard = true
  public disableSuper = true
  public isLoading = true;
  
  constructor(
    private readonly permissions: PermissionsService,
    private _router: Router, 
    private _oktaStateService: OktaAuthStateService, 
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }

  public ngOnInit(): void {

    this.name$ = this._oktaStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.idToken?.claims.name ?? '')
    );

    this.permissions.getUserLevel().subscribe(level => {

      this.disableStandard = level < UserLevel.STANDARD
      this.disableSuper = level < UserLevel.SUPER
      if (this.isAuthorized) {
        this.isLoading = false;
      } else {
        this._oktaAuth.signInWithRedirect()
      }
    })
  }

  public get isAuthorized(): Observable<boolean> {
    return this._oktaStateService.authState$.pipe(
      map((authState: AuthState) => !!authState && !!authState.isAuthenticated)
    );
  }

  public async signIn() : Promise<void> {
    await this._oktaAuth.signInWithRedirect();
  }

  public async signOut(): Promise<void> {
    await this._oktaAuth.signOut();
  }
}
